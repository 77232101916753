import {useCallback} from 'react'
import {Helmet} from 'react-helmet-async'
import {PageRendererProps} from 'gatsby'
import queryString from 'query-string'

import TextSignup from 'src/components/TextSignup'

// NOTE: this form is used as an embed on resource pages
// The height needs to be set in the CMS
// <iframe style="width: 100%; height: 160px" src="/embedded-text-signup-hiring-day/" />

const EmbeddedTextSignupHiringDay = ({location}: PageRendererProps) => {
  const {notrack} = queryString.parse(location.search)
  const trackingEnabled = notrack !== '1'

  const successLocation = useCallback(
    () => `${window.top?.location.pathname}#success`,
    []
  )

  const trackClick = useCallback(() => {
    const snaptr = (window as any).snaptr

    if (snaptr) {
      snaptr('track', 'SUBSCRIBE')
    }
  }, [])

  return (
    <>
      <TextSignup
        phoneOnly
        submitText="Pre-Register"
        errorText="Looks like you're already receiving Get Schooled text messages. Please text #HiringDay to 33-55-77 to pre-register."
        onSuccess={trackClick}
        onError={trackClick}
        successLocation={successLocation}
        successMessage="Success! You're pre-registered for #HiringDay"
      />
      {trackingEnabled ? <SnapTrackingPixel /> : null}
    </>
  )
}

const SnapTrackingPixel = () => {
  if (!process.env.GATSBY_SNAP_PIXEL_ID) {
    return null
  }

  return (
    <Helmet>
      <script type="text/javascript" async>{`
(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
r.src=n;var u=t.getElementsByTagName(s)[0];
u.parentNode.insertBefore(r,u);})(window,document,
'https://sc-static.net/scevent.min.js');

snaptr('init', '${process.env.GATSBY_SNAP_PIXEL_ID}');

snaptr('track', 'PAGE_VIEW');`}</script>
    </Helmet>
  )
}

export default EmbeddedTextSignupHiringDay
